import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import app from "../firebaseconfig";

const auth = getAuth(app);

const createUser = (email,password)=>createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        alert("Done");

        // ...
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage)
        // ..
    });

export default createUser