import { useEffect, useState } from "react"
import createUser from "../functions/CreateUser"
import signIn from "../functions/LoginUser"
import app from "../firebaseconfig";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";



const Login = () => {
    const auth = getAuth(app);
    const user = auth.currentUser;
    
    useEffect(() => {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    })
    // createUser('test@gmail.com','test@123')
    // createUser('Client1@gmail.com','Client@123')
    const [email, setEmail] = useState()
    const [password,setPass] = useState()
    const navigate = useNavigate();
    const handleClick = async () => {
        // alert(email,password)
        console.log(auth);
        signIn(email, password)
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = user.uid;
                if (user.email == 'test@gmail.com') {
                    navigate('/admin')
                }
                else {
                    navigate('/user')
                }
                // ...
            } else {
                // User is signed out
                // ...
            }
        });
    }

    return (
        <>
            <div className="flex justify-center text-xl mt-6">
                <h1>Login</h1>
            </div>
            <form className="cf mt-5 flex flex-col justify-center items-center">
                <div className="half left cf flex flex-col items-center ">
                    <input type="email" id="input-email" placeholder="Email address" className='bg-gray-200 px-20 py-2 my-2 rounded-full' onChange={(e)=>setEmail(e.target.value)}/>
                    <input type="text" id="input-subject" placeholder="Password" className='bg-gray-200 px-20 py-2 my-2 rounded-full' onChange={(e)=>{setPass(e.target.value)}} />
                </div>
                
                <div onClick={handleClick} id="input-submit" className='items-center bg-gray-300 px-20 py-2 my-2  rounded-full'>Submit</div>
            </form>
        </>
    )
}

export default Login