
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { arrayRemove, arrayUnion, collection, deleteDoc, doc, getDocs, getFirestore, query, updateDoc } from 'firebase/firestore';
import  { useEffect, useState } from 'react'
import app from '../firebaseconfig';
import { useNavigate } from "react-router-dom";
const Req = () => {

  const [res, setres] = useState([])
  const [filres, setfilres] = useState([])
  const [fetched, setfetch] = useState(false)
  const [recID,setReciID] = useState()
  const [recEmail,setReciEmail] = useState()
  const db = getFirestore(app);
  const navigate = useNavigate();
  const fetching = async () => {
    const data = []
    const filtered =[]
    const q = query(collection(db, "res"));
    const querySnapshot = await getDocs(q);
    setfetch(true)
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data())
    });
    setres(data);
    const filter = () => {
      data.map(dt => {
        if (dt.ReqCount > 0) {
          for (let i = 0; i < dt.ReqCount; i++){
            const newReq = [dt.ReqFrom[i], dt.ID]
            filtered.push(newReq);
          }
        }
      })
      setfilres(filtered);
    }
    filter()
  }


  useEffect(() => {

    fetching()
  }, [])
  
  const handleApproval =async() => {
    const ref= doc(db, "res", recID);

    const update = await updateDoc(ref, {
      AllocatedTo: arrayUnion(recEmail),
      // ReqFrom: arrayRemove(recEmail)

    });
    alert('Approved!');
    navigate('/admin')
  }

  return (
      <>
      <div className="flex flex-col items-center justify-center my-4">
        <h2 className="text-xl">Pending Requests</h2>
        <div className="text-xl mt-8"><table>
          <tr>
            <th className="px-8 py-2">ID</th>
            <th className="px-8 py-2">Request From</th>
          </tr>
          {res && filres.map(filre => {
            return (
              <tr className="px-8 ">
                <td>{filre[1]}</td>
                <td>{filre[0]}</td>
              </tr>
            )
          })
          }
        </table></div>
      </div>
      <hr />
      <div className="text-xl flex flex-col justify-center items-center mt-8">
        <h1>Approve One of the above Requests</h1>
        <div className="flex flex-col items-center justify-center mt-8">
          <span>Resource ID</span>
          <input type="number" className="bg-gray-100 mt-4" onChange={(e)=>{setReciID(e.target.value)}}/>
        </div>
        <div className="flex flex-col items-center justify-center mt-8">
          <span>Recipient Email</span>
          <input type="Email" className="bg-gray-100 mt-4" onChange={(e) =>{setReciEmail(e.target.value)}} />
        </div>
        <div className="rounded-full bg-gray-200 px-7 py-2 mt-4" onClick={handleApproval}>
          Approve
        </div>
      </div>
      </>
  )
}

export default Req