import { Link, Outlet } from "react-router-dom"

const Home = () => {
    return (<>
        <div className="flex flex-col items-center justify-center mt-8 py-3">
            <div className="bg-gray-200 hover:bg-gray-300 text-2xl my-2 rounded-full">
                <Link to="/login" className="pt-4 px-8">Admin Portal</Link>
            </div>
            <div className="bg-gray-200 hover:bg-gray-300 text-2xl my-2 rounded-full">
                <Link to="/user" className="pt-4 px-8" >User Portal</Link>
            </div>     
        </div>
        <div className=" flex justify-center  hover:cursor-pointer  my-96 py-2">
            <Link to='/aboutUs'>About Us</Link>
        </div>
    </>
    )
}

export default Home