import React, { useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate, useSearchParams } from 'react-router-dom';
import app from '../firebaseconfig';
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';

const ViewRes = () => {
    let Uemail = '';
    const navigate = useNavigate();
    const db = getFirestore(app);
    const auth = getAuth();
    const email = () => onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            Uemail = user.email
            // ...
        } else {
            // User is signed out
            // ...
            navigate('/login');
        }
    })
    email()
    const [allo, setAllo] = useState();
    const [reso, setreso] = useState();

    useEffect(() => {
        lib()
    },[])
    const lib =  () => {
        
        const Res = []
        
        if (allo) {
            allo.map(async all => {
                const docRef = doc(db, "res", `${all}`);
                const docSnap = await getDoc(docRef);
                const data = docSnap.data();
                Res.push([data.ID,data.Name,data.resLink]);
                console.log(data);
            })
}

        setreso(Res);
        console.log(reso);
    }

    const getData = async () => {
        const ress =[]
        const q = query(collection(db, "res"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            const data = doc.data();
            const allocation = data.AllocatedTo;
            // console.log(allocation)   ;
            
            if (allocation.includes(Uemail)) {
                ress.push(data.ID)
            }
        });
        setAllo(ress);        


    }

    useEffect(() => {
        getData();
    },[])

  return (
      <>
          <div className='flex flex-col items-center mt-8'>
              <h1 className='text-xl'>All appproved resources appear here!</h1>
              <table className='text-xl'>
                  <tr >
                      <th colSpan={3} className='py-4'>
                          <tr>List of Allocated Resources</tr>
                      </th>
                  </tr>
                      <tr>
                          <th className='px-4 py-4'>
                              Res ID
                          </th>
                          <th className='px-4 py-4'>
                              Res Name
                          </th>
                          <th className='px-4 py-4'>
                              Link
                          </th>
                      </tr>
                  {reso && reso.map(resi => {
                      return (<tr>
                          <td className='px-2 py-4'>{resi[0]}</td>
                          <td className='px-2 py-4'>{resi[1]}</td>
                          <td className='px-2 py-4'><a href={resi[2]} className='text-blue-500'>Link To Resource</a></td>
                      </tr>)
})}
              </table>
          </div>
      </>
    )
}

export default ViewRes