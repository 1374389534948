// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAhq8hfdCmbId2TFoOoXuBe55H_73m5LTM",
    authDomain: "resourceallocater.firebaseapp.com",
    projectId: "resourceallocater",
    storageBucket: "resourceallocater.appspot.com",
    messagingSenderId: "421305619173",
    appId: "1:421305619173:web:e6dc3a3986abfdbc193a0c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app