import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import app from "../firebaseconfig";
import { useNavigate } from "react-router-dom";
const auth = getAuth(app);
const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        alert("Sign In Successfull!!")
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage)
    }
    );

export default signIn