import React from 'react'
import { Link } from 'react-router-dom'

const Client = () => {
    return (
        <>
            <div className="flex justify-center my-8">
                <h3 className="text-xl">Client Portal</h3>
            </div>
            <div className="flex flex-col items-center">
                <Link className="py-2 bg-gray-100 px-8 mb-4 rounded-full" to='/reqres'>Requests a Resource</Link>
                
                <Link className="py-2 bg-gray-100 px-8 mb-4 rounded-full" to='/viewres'>View all Resources</Link>
            </div>

            <div className=" flex justify-center hover:cursor-pointer my-96 py-2">
                <Link to='/aboutUs'>About Us</Link>
            </div>
        </>
    )
}

export default Client