import { useState } from "react"
import { arrayUnion, collection, doc, getDoc, getFirestore, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import app from "../firebaseconfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const RequestRes = () => {
    const random_ID = Math.floor(10000 + Math.random() * 90000);
    const navigate = useNavigate();
    const db = getFirestore(app);
    const auth = getAuth();
    let Uemail = '';
    const email =()=> onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            Uemail = user.email
            // ...
        } else {
            // User is signed out
            // ...
            navigate('/login');
        }
    })
    email()

    const [resID, setID] = useState()
    const [flag, setflag] = useState(false)
    const [data, setData] = useState();
    const [requested,setReq] = useState(false)
    const handleClick = async () => {

        const users =[]
        const docRef = doc(db, "res", resID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setData(docSnap.data())
        }
        if (data) {
            data.ReqFrom.map(ref => {
                users.push(ref)
            })
        }
        setflag(true);
        
        console.log(
            users
        );
        if (users && users.includes(String(Uemail))) {
            setReq(true);
        }
        else {
            setReq(false);
        }
        // console.log(docSnap.data());
        
    }

    const handleRequest = async(e) => {
        
        if (!data.ReqFrom.includes(Uemail)) {
                    
            data.ReqFrom.push(Uemail);
            data.ReqCount++;
            console.log(data);
            const ref = doc(db, "res", resID);
            
            await updateDoc(ref, {
                ReqCount: `${data.ReqCount}`,
                ReqFrom: arrayUnion(Uemail)
            });
        }

        else {
            alert('Already requested')
        }
                // ...
           
        
        
}

    return (
        <><div className='flex flex-col justify-center items-center mt-10 text-xl'>

            <h1 className='text-2xl mb-4'>Request a resource</h1>
            <h2 className='text-lg'>Resource ID necessary</h2>
            <div className="flex flex-col items-center justify-center mt-8">
                <form>

                    <label htmlFor="resourceType">Resource ID:</label>
                    <input className="bg-gray-200" type="text" id="resourceType" name="resourceType" required onChange={(e) => setID(e.target.value)} /><br /><br />

                    <div id="input-submit" onClick={handleClick} className='w-max items-center bg-gray-300 px-20 py-2 my-2 hover:cursor-pointer  rounded-full'>Search</div>            </form>
            </div>
        </div>{
                flag &&
                <div className="text-xl flex flex-col justify-center items-center mt-6">
                        <h1 className="py-2">Resource ID : {data.ID}</h1>
                        <h2 className="py-2">Resource Name : {data.Name}</h2>
                        <h2 className="py-2">Resource Type : {data.Type}</h2>
                        <h2 className="py-2">Resource Waiting Requests : {data.ReqCount}</h2>
                </div>
            }
            {data && <div className=" flex items-center justify-center " onClick={handleRequest}>
                <h1 className=" text-2xl items-center bg-gray-300 px-20 py-2 my-2 hover:cursor-pointer  rounded-full">{requested ? 'Already Requested':'Request Resource'}</h1>
            </div>}
        </>
    )
}

export default RequestRes