import { useState } from "react"
import { doc, getFirestore, setDoc, Timestamp } from "firebase/firestore";
import app from "../firebaseconfig";
import { useNavigate } from "react-router-dom";

const List = () => {
    const random_ID = Math.floor(10000 + Math.random() * 90000);

    const db = getFirestore(app);

    const [resName, setName] = useState()
    const [resType, setType] = useState()
    const [maxHrs, setHrs] = useState()
    const [link, setLink] = useState()
    const navigate = useNavigate();
    const handleClick = async () => {
        const docData = {
            ID: random_ID,
            Name: resName,
            Type: resType,
            Hours: maxHrs,
            resLink:link,
            AllocatedTo: '',
            ReqCount: 0,
            ReqFrom: []

        }

        const docRef = doc(db, "res", `${random_ID}`);
        await setDoc(docRef, docData);

        alert('Resource Listed Successfully')
        navigate('/admin');

    }
    return (
        <>
            <div className="flex flex-col items-center mt-8">
                <h1 className="text-xl mb-4">List a New Resource</h1>
                <form>
                    <label htmlFor="resourceName">Resource Name:</label>
                    <input className="bg-gray-200" type="text" id="resourceName" name="resourceName" required onChange={(e) => setName(e.target.value)} /><br /><br />
                    <label htmlFor="resourceType">Resource Type:</label>
                    <input className="bg-gray-200" type="text" id="resourceType" name="resourceType" required onChange={(e) => setType(e.target.value)} /><br /><br />
                    <label htmlFor="maxHours">Max Hours:</label>
                    <input className="bg-gray-200" type="number" id="maxHours" name="maxHours" required onChange={(e) => setHrs(e.target.value)} /><br /><br />
                    <label htmlFor="link">Link To Resource</label>
                    <input className="bg-gray-200" type="text" id="link" name="link" required onChange={(e) => setLink(e.target.value)} /><br /><br />
                    <div id="input-submit" onClick={handleClick} className='w-max items-center bg-gray-300 px-20 py-2 my-2 hover:cursor-pointer  rounded-full'>Submit</div>            </form>
            </div>
        </>
    )
}

export default List