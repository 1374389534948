import './App.css';
import { Route, Routes } from 'react-router-dom'
import Home from './Component/Home';
import Nav from './Component/Nav';
import Admin from './Component/Admin';
import Req from './Component/Req';
import Login from './Component/Login';
import NewUser from './Component/NewUser';
import List from './Component/List';
import DeleteRes from './Component/DeleteRes';
import Client from './Component/Client';
import RequestRes from './Component/RequestRes';
import ViewRes from './Component/ViewRes';
function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route index element={<Home />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<NewUser />} />
        <Route path='/req' element={<Req />} />
        <Route path='/newreq' element={<List />} />
        <Route path='/deletereq' element={<DeleteRes />} />
        <Route path='/user' element={<Client />} />
        <Route path='/reqres' element={<RequestRes />} />
        <Route path='/viewres' element={<ViewRes />} />
      </Routes>
    </div>
  );
}

export default App;
