import { collection, deleteDoc, doc, getDocs, getFirestore, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import app from '../firebaseconfig';

const DeleteRes = () => {

    const db = getFirestore(app);
    // var res = []
    const [res, setres] = useState([])
    const [fetched,setfetch]=useState(false)
    const l = query(collection(db, "res"));

    const fetching = async () => {
            const data =[]
            const q = query(collection(db, "res"));
            const querySnapshot = await getDocs(q);
            setfetch(true)
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                data.push(doc.data())
            });
            setres(data);
        }
    useEffect(() => {
        
        fetching()
    },[])

    // const deleteDc = async (id) => {
    //     await deleteDoc(doc(db, "res", id));
    // }
    // const handleDelete = (e) => {
    //     const docid = e.target.id
    //     console.log(docid);
    //     deleteDc(docid);
    //     alert(`Resource with ID ${docid} Deleted`)
    // }

  return (
      <>
          <h3 className='flex justify-center text-xl mt-8 font-semibold'>Click on the resource to Delete</h3>
          <div className='text-lg px-2 flex justify-center mt-8'>
              
              {res && <table>
                  <tr>
                      <th className='px-6 py-2'>ID</th>
                      <th className='px-6 py-2'>Type</th>
                      <th className='px-6 py-2'>Name</th>
                  </tr>
                  {res.map((re) => {
                      return (<tr  className='hover:bg-gray-100' key={res.ID}>
                          <td className='px-6 py-2'>{re.ID}</td>
                          <td className='px-6 py-2'>{re.Type}</td>
                          <td className='px-6 py-2'>{re.Name}</td>
                      </tr>)
                  })}
              </table>}
          </div>
          
      </>
    )
}

export default DeleteRes