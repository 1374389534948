import { Link } from "react-router-dom"

const Admin = () => {
    return (
        <>
            <div className="flex justify-center my-8">
                <h3 className="text-xl">Admin Portal</h3>
            </div>
            <div className="flex flex-col items-center">
                <Link className="py-2 bg-gray-100 px-8 mb-4 rounded-full" to='/req'>Pending Requests</Link>
                <Link className="py-2 bg-gray-100 px-8 mb-4 rounded-full" to='/newreq'>List New Resource</Link>
                <Link className="py-2 bg-gray-100 px-8 mb-4 rounded-full" to='/deletereq'>Remove Existing Resource</Link>
            </div>

            <div className=" flex justify-center hover:cursor-pointer my-96 py-2">
                <Link to='/aboutUs'>About Us</Link>
            </div>
        </>
    )
}

export default Admin