const vitLogo = require("../assets/vit_logo.png")

const Nav = () => {
  return (
      <>
          <div className="w-full flex flex-col items-center  ">
              <h1 className="text-2xl my-2">Resource Sharing Platform</h1>
              <div className="w-1/2 my-4">
                  <img src={vitLogo} alt="VIT LOGO" />
              </div>
          </div>
      </>
  )
}

export default Nav